import React, { useEffect, useState } from 'react';
import api from '../../api';
import { regnskapUrl, spleisUrl } from '../../config';
import moment from 'moment';
import { formatMoneyKr, formatNumber } from '../../formatters';
import Button from '../../Button/Button';
import { showError, showInfo } from '../../store';
import styles from './ProjectClaims.module.scss';

export const ProjectClaims = ({ project }) => {
    const [processingBlock, setProcessingBlock] = useState(false);
    const [processingComment, setProcessingComment] = useState(false);
    const [claims, setClaims] = useState({});
    const [fetching, setFetching] = useState(false);

    const blockedForPayout = 'BLOCKED_FOR_PAYOUT';
    const isBlockedForPayout = (project.blocked?.blocked_types || []).includes(blockedForPayout);

    useEffect(() => {
        let isCancelled = false;
        if (project?.id) {
            setFetching(true);
            api.get(`${regnskapUrl}/api/claims/${project.id}`)
                .then((claims) => {
                    if (!isCancelled) {
                        setClaims(claims);
                    }
                })
                .catch(() => console.log('Kunne ikke hente fordringer'))
                .finally(() => {
                    if (!isCancelled) {
                        setFetching(false);
                    }
                });
        }
        return () => {
            isCancelled = true;
        }
    }, [project?.id]);

    const claimsVipps = Number(claims?.fordringvipps ?? '0');
    const claimsStripe = Number(claims?.fordringstripe ?? '0');
    const claimsInvoice = Number(claims?.fordringfaktura ?? '0');
    const totalClaims = claimsVipps + claimsStripe + claimsInvoice;
    const claimsPercentage = (100 * totalClaims) / project.collected_amount;

    const collectedText = formatMoneyKr(project.collected_amount ?? 0);
    const vippsClaimsText = formatMoneyKr(claims.fordringvipps ?? 0);
    const stripeClaimsText = formatMoneyKr(claims.fordringstripe ?? 0);
    const invoiceClaimsText = formatMoneyKr(claims.fordringfaktura ?? 0);
    const dateText = `Per ${moment.utc(5, 'H').local().format('LLL')}`;

    const blockAndComment = (type) => {
        setProcessingBlock(true);
        setProcessingComment(true);

        Promise.all([
            api
                .post(`${spleisUrl}/api/admin/project/${project.id}/block/${type}`)
                .then(() => {
                    setProcessingBlock(false);
                    showInfo('Prosjekt ble blokkert');
                })
                .catch((e) => {
                    setProcessingBlock(false);
                    showError(`Feil ved blokkering: ${e}`);
                }),
            api
                .post('/api/comments', {
                    comment: `Blokkert pga. fordringer\n\nVipps: ${vippsClaimsText}\nStripe: ${stripeClaimsText}\nFaktura: ${invoiceClaimsText}\n(${formatNumber(
                        claimsPercentage
                    )}% av ${collectedText})`,
                    kis: { project: project.id },
                    postToSlack: false,
                })
                .then(() => {
                    setProcessingComment(false);
                })
                .catch((e) => {
                    setProcessingComment(false);
                    showError(`Feil ved kommentering: ${e}`);
                }),
        ]).then(() => window.notify());
    };

    if (fetching) {
        return (
            <section className="claims-container">
                <header className="project-table-data-header">
                    <div>Henter...</div>
                </header>
            </section>
        );
    }

    return (
        <section className="claims-container">
            <header className="project-table-data-header">
                <div>{dateText}</div>
            </header>
            <span className={'strong'}>Vipps: </span>{' '}
            <span className={claimsVipps > 0 ? 'red' : ''}>{vippsClaimsText}</span>,{' '}
            <span className={'strong'}>Stripe: </span>{' '}
            <span className={claimsStripe > 0 ? 'red' : ''}>{stripeClaimsText}</span>,{' '}
            <span className={'strong'}>Faktura: </span>{' '}
            <span className={claimsInvoice > 0 ? 'red' : ''}>{invoiceClaimsText}</span>,{' '}
            <span className={'strong'}>Prosent: </span>{' '}
            <span className={Math.round(claimsPercentage) > 0 ? 'red' : ''}>{formatNumber(claimsPercentage)}%</span>
            {!isBlockedForPayout && totalClaims >= 10_000 && (
                <div>
                    <Button
                        className={styles.blockButton}
                        disabled={processingBlock || processingComment}
                        onClick={() => {
                            blockAndComment(blockedForPayout);
                        }}
                    >
                        Blokkér og kommentér
                    </Button>
                </div>
            )}
        </section>
    );
};
