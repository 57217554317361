import React, { useEffect, useState } from 'react';
import api from '../api';
import { spleisUrl } from '../config';
import { showError } from '../store';
import useLocalStorage from '../localstorage';
import style from './ProjectListFilter.module.scss';

export const PROJECT_FILTER_GLOBAL_LOCAL_STORAGE_KEY = 'PROJECT_FILTER_GLOBAL';
const localStorageDefaultValue = {
    event: 'ALL',
};

const localStorageValue = localStorage.getItem(PROJECT_FILTER_GLOBAL_LOCAL_STORAGE_KEY);
const localStorageValueParsed = localStorageValue ? JSON.parse(localStorageValue) : localStorageDefaultValue;

export const filterProjectsGlobal = (projects) => {
    if (localStorageValueParsed.event === 'ALL') {
        return projects;
    } else if (localStorageValueParsed.event === 'NO_EVENT') {
        return projects.filter((project) => project.event_id === undefined || project.event_id === null);
    } else if (localStorageValueParsed.event === 'HAS_EVENT') {
        return projects.filter((project) => project.event_id !== undefined && project.event_id !== null);
    } else {
        const eventIdConfig = parseInt(localStorageValueParsed.event, 10);
        return projects.filter((project) => project.event_id === eventIdConfig);
    }
};

const ProjectListFilter = () => {
    const [allEvents, setAllEvents] = useState([]);
    const [projectListFilterConfig, setProjectListFilterConfig] = useLocalStorage(
        PROJECT_FILTER_GLOBAL_LOCAL_STORAGE_KEY,
        localStorageDefaultValue
    );

    useEffect(() => {
        api.get(`${spleisUrl}/api/admin/event/all`)
            .then((res) => setAllEvents(res?.filter((event) => event.name)))
            .catch(() => showError('Feil ved henting alle eventer'));
    }, []);

    const defaultEventChosen = projectListFilterConfig.event === 'ALL';

    return (
        <select
            className={[style.select, defaultEventChosen ? '' : style.nonDefault].join(' ')}
            onChange={(e) => {
                if (e.target.value) {
                    setProjectListFilterConfig({
                        ...projectListFilterConfig,
                        event: e.target.value,
                    });
                    location.reload();
                }
            }}
            disabled={allEvents.length === 0}
            value={projectListFilterConfig.event}
        >
            <option value="ALL">Vis alle prosjekter</option>
            <option disabled>-------</option>
            <option value="HAS_EVENT">Prosjekter med event</option>
            <option value="NO_EVENT">Prosjekter uten event</option>
            <option disabled>-------</option>
            {allEvents.map((event) => (
                <option key={event.id} value={event.id}>
                    {event.name}
                </option>
            ))}
        </select>
    );
};

export default ProjectListFilter;
