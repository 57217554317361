import ProjectLink from '../SmartLinks/ProjectLink';
import React from 'react';
import './SubProjectList.scss';

export const SubProjectList = ({ data }) => {
    if (!data || !data.length) {
        return null;
    }
    return (
        <div className={'subprojects'}>
            <header>
                <h2>Deltaker på dugnad:</h2>
            </header>
            {data.map((subProject) => (
                <div key={subProject.subProject_id} className={`project-element`}>
                    <span className={'project-id'}>
                        <ProjectLink id={subProject.project_id} />
                    </span>
                </div>
            ))}
        </div>
    );
};