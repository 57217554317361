import React, { useEffect, useRef, useState } from 'react';
import api from '../api';
import { spleisUrl } from '../config';
import { showError, showInfo } from '../store';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import Button from '../Button/Button';
import 'react-image-crop/dist/ReactCrop.css';
import styles from './ShowTemplate.module.scss';

export const TemplateRowImage = ({ label, value, field, templateId, updateTemplate, setTemplate }) => {
    const fileUpload = useRef(null);
    const [uploading, setUploading] = useState(false);
    const [crop, setCrop] = useState();
    const [completedCrop, setCompletedCrop] = useState();
    const [imgSrc, setImgSrc] = useState();
    const [file, setFile] = useState();

    const ASPECT = 1200 / 624;

    const uploadFile = async () => {
        if (!file) {
            return;
        }
        setUploading(true);
        api.uploadImage(`${spleisUrl}/api/admin/template/${templateId}/uploadImage`, 'POST', file, completedCrop)
            .then((res) => {
                setTemplate(res);
                setUploading(false);
                setImgSrc(res.img_url);
                setFile(undefined);
                showInfo('Bildet ble lastet opp!');
            })
            .catch((err) => {
                console.log(err);
                showError('Feil ved filopplasting ' + err);
                setUploading(false);
            });
    };

    const deleteFile = async () => {
        await api.remove(`${spleisUrl}/api/admin/template/${templateId}/image`);
        updateTemplate(null, field);
    };

    const onSelectFile = () => {
        setFile(fileUpload.current.files[0]);
        setCrop(undefined);
        const reader = new FileReader();
        reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
        reader.readAsDataURL(fileUpload.current.files[0]);
    };

    function centerAspectCrop(mediaWidth, mediaHeight) {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90,
                },
                ASPECT,
                mediaWidth,
                mediaHeight
            ),
            mediaWidth,
            mediaHeight
        );
    }
    function onImageLoad(e) {
        const { width, height } = e.currentTarget;
        setCrop(centerAspectCrop(width, height));
    }

    const DisplayImage = () => {
        return <img src={value} style={{ width: '100%' }} alt={'Template image'} />;
    };

    if (value) {
        return (
            <div className={styles['template-image-edit-container']}>
                <div className={styles['template-image-upload-container']}>
                    <label className={[styles['template-label'], styles['template-label-image']].join(' ')}>
                        {label}
                        <div className={styles.imageUrl}>{value}</div>
                    </label>
                    <div className={styles['upload-image']}>
                        <DisplayImage />
                    </div>
                    <Button onClick={() => deleteFile()}>Slett</Button>
                </div>
            </div>
        );
    }
    return (
        <div className={styles['template-image-edit-container']}>
            <div className={styles['template-image-upload-container']}>
                <label className={[styles['template-label'], styles['template-label-image']].join(' ')}>
                    {label}
                    <div className={styles.imageUrl}>{value}</div>
                    <span className={styles['upload-button']}>Velg</span>

                    <input
                        id={'file-upload'}
                        type={'file'}
                        style={{ display: 'none' }}
                        accept="image/png, image/jpeg, image/webp"
                        onChange={onSelectFile}
                        ref={fileUpload}
                    />
                </label>
                {file && (
                    <>
                        <div className={styles['upload-image']}>
                            <ReactCrop
                                crop={crop}
                                onChange={(_, percentCrop) => setCrop(percentCrop)}
                                onComplete={(_, percentCrop) => setCompletedCrop(percentCrop)}
                                aspect={ASPECT}
                            >
                                <img
                                    alt="Crop me"
                                    src={imgSrc}
                                    onLoad={onImageLoad}
                                    style={{ transform: `scale(1) rotate(0deg)` }}
                                />
                            </ReactCrop>
                        </div>

                        <Button onClick={() => uploadFile()}>{uploading ? 'Laster opp..' : 'Last opp'}</Button>
                    </>
                )}
            </div>
        </div>
    );
};
