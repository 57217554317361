import React, { useContext, useState } from 'react';
import ProjectContext from '../project-context';
import Button from '../../Button/Button';
import styles from './ProjectOptions.module.scss';
import ChangeLocation from '../ChangeLocation/ChangeLocation';
import AddToEvent from '../AddToEvent/AddToEvent';

import ToggleProjectSearchVisibility from '../ToggleProjectSearchVisibility/ToggleProjectSearchVisibility';
import ChangeEndsAt from '../ChangeEndsAt/ChangeEndsAt';
import ChangePricing from '../ChangePricing/ChangePricing';
import ChangeProjectProfile from '../ChangeProjectProfile/ChangeProjectProfile';
import ChangeProjectProfileTech from '../ChangeProjectProfileTech/ChangeProjectProfileTech';
import HideGiversAndComments from '../HideGiversAndComments/HideGiversAndComments';
import ToggleSubprojects from '../ToggleSubprojects/ToggleSubprojects';
import CheckTerms from '../CheckTerms/CheckTerms';
import ToggleSelfServeRefunds from '../ToggleSelfServeRefunds/ToggleSelfServeRefunds';
import ToggleHideContent from '../ToggleHideContent/ToggleHideContent';
import { ImmediateRefund } from '../ImmediateRefund/ImmediateRefund';
import PromotedGiverLimit from '../PromotedGiverLimit/PromotedGiverLimit';

const ProjectOptions = ({ buttonClass = '' }) => {
    const project = useContext(ProjectContext);
    const [showOptions, setShowOptions] = useState(false);

    const toggleOptions = () => {
        setShowOptions(!showOptions);
    };

    return (
        <>
            <Button className={buttonClass} onClick={toggleOptions}>
                Flere valg
            </Button>

            {showOptions && (
                <div className={styles.optionsContainer}>
                    <h3>Flere valg for prosjektet</h3>

                    <ul className={styles.options}>
                        <li>
                            <ChangeLocation />
                        </li>
                        <li>
                            <AddToEvent project={project} />
                        </li>
                        <li>
                            <ToggleProjectSearchVisibility />
                        </li>
                        <li>
                            <HideGiversAndComments />
                        </li>
                        <li>
                            <ChangeEndsAt />
                        </li>
                        <li>
                            <ChangePricing />
                        </li>
                        <li>
                            <ChangeProjectProfile project={project} />
                        </li>
                        <li>
                            <ChangeProjectProfileTech project={project} />
                        </li>
                        <li>
                            <ToggleSubprojects />
                        </li>
                        <li>
                            <ImmediateRefund />
                        </li>
                        <li>
                            <ToggleSelfServeRefunds />
                        </li>
                        <li>
                            <CheckTerms />
                        </li>
                        <li>
                            <ToggleHideContent />
                        </li>
                        <li>
                            <PromotedGiverLimit project={project} />
                        </li>
                    </ul>
                </div>
            )}
        </>
    );
};

export default ProjectOptions;
