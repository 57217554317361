const urls = {};

if (window.location.hostname === 'admin.spleis.no' || window.location.hostname === 'adminuru.spleis.no') {
    urls.payment = 'https://payment.spleis.no';
    urls.spleis = 'https://www.spleis.no';
    urls.spleisFront = 'https://www.spleis.no';
    urls.regnskap = 'https://regnskap.spleis.no';
} else if (window.location.hostname === 'admin.test.spleis.no') {
    urls.payment = 'https://payment.test.spleis.no';
    urls.spleis = 'https://www.test.spleis.no';
    urls.spleisFront = 'https://www.test.spleis.no';
    urls.regnskap = 'https://regnskap.test.spleis.no';
} else {
    urls.payment = 'http://localhost:7000';
    urls.spleis = 'http://localhost:3000';
    urls.spleisFront = 'https://localhost:8080';
    urls.regnskap = 'http://localhost:9999';
}

export const spleisUrl = urls.spleis;
export const spleisFrontUrl = urls.spleisFront;
export const paymentUrl = urls.payment;
export const regnskapUrl = urls.regnskap;
export const intercomUrl = 'https://app.intercom.com/apps/jibf20yf';
