const getFlaggedKeywords = (project) =>
    project?.blocked?.flags?.filter((flag) => flag.type === 'keyword').map((flag) => flag.reason) || [];

const colorizeFlaggedKeyword = (text, keyword) => {
    if (!text) {
        return text;
    }
    const regEx = new RegExp(keyword, 'ig');
    return text.replace(regEx, `<span class="flaggedKeywordInText">$&</span>`);
};

export const colorizeFlaggedKeywordsInText = (text, project) => {
    const flaggedKeywords = getFlaggedKeywords(project);
    return flaggedKeywords.reduce((acc, cur) => {
        return colorizeFlaggedKeyword(acc, cur);
    }, text);
}
