import React, { Component } from 'react';
import moment from 'moment';
import { spleisUrl } from '../config';
import './Audits.scss';
import { formatDate, sortObjectArrayByDateField } from '../dates';
import VisibleIf from '../VisibleIf/VisibleIf';
import ShowMore from '../ShowMore/ShowMore';
import { showError } from '../store';
import api from '../api';
import Collapsable from '../Collapsable/Collapsable';
import UserLink from '../SmartLinks/UserLink';

class Audits extends Component {
    state = {
        filter: '',
    };

    filter = (e) => this.setState({ filter: e.target.value });
    render = () => {
        const allAudits = [...this.props.audits, ...this.props.adminAudits];
        const audits = sortObjectArrayByDateField(allAudits || [], 'created_at', true);
        const filteredAudits = audits.filter((audit) => {
            const { filter } = this.state;
            if (!filter) {
                return true;
            }
            const regexFilter = new RegExp(filter, 'gi');
            if (audit.description) {
                return audit.description.match(regexFilter);
            }
            return audit.event && audit.event.match(regexFilter);
        });
        return (
            <Collapsable
                title="Historikk"
                className="audits-container"
                defaultCollapsed={true}
                localStorageKey="collapse-project-audits"
            >
                <input onChange={this.filter} className="audits-filter" placeholder="Filtrer" />
                <VisibleIf isVisible={allAudits.length > 750}>
                    <span className="audits-filter__warning">
                        Det er mye historikk, så det kan gå litt tregt å filtrere
                    </span>
                </VisibleIf>

                <VisibleIf isVisible={filteredAudits.length >= 1}>
                    <ShowMore
                        items={filteredAudits}
                        listCreator={(showMoreItems) => (
                            <ul className="audits-list">
                                {showMoreItems.map((audit, index) => (
                                    <li key={index} className="audit-wrapper">
                                        <Audit audit={audit} />
                                    </li>
                                ))}
                            </ul>
                        )}
                    />
                </VisibleIf>
            </Collapsable>
        );
    };
}

class Audit extends Component {
    constructor(props) {
        super(props);
        this.state = { expanded: false };
    }

    toggleExpanded() {
        this.setState({ expanded: !this.state.expanded });
    }

    render() {
        const audit = this.props.audit;
        return (
            <>
                {this.props.admin ? <div>{audit.user_email}</div> : ''}
                <div className={`audit-meta ${this.props.admin ? 'admin-audit' : ''}`}>
                    {audit.event ?? audit.description}{' '}
                    <span className="audit-time" title={formatDate(audit.created_at)}>
                        {moment(audit.created_at).fromNow()}
                    </span>
                </div>
                {audit.metadata && (
                    <div
                        className="audit-container expandable"
                        style={{ maxHeight: this.state.expanded ? 'none' : '56px' }}
                        onClick={() => this.toggleExpanded()}
                    >
                        {JSON.stringify(audit.metadata)}
                    </div>
                )}
                {!audit.metadata && audit.user_id && (
                    <div className="audit-container">
                        Utført av bruker <UserLink id={audit.user_id} />
                    </div>
                )}
            </>
        );
    }
}

class AuditContainer extends Component {
    auditToAdminAudit = { userId: 'user', projectId: 'project' };

    constructor(props) {
        super(props);
        this.state = {
            audits: [],
            adminAudits: [],
        };
    }

    componentDidMount() {
        this.fetchData(this.props.lookupKey, this.props.id);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.id !== nextProps.id || this.props.lookupKey !== nextProps.lookupKey) {
            this.fetchData(nextProps.lookupKey, nextProps.id);
        }
    }

    fetchData(lookupKey, id) {
        if (lookupKey && id) {
            this.fetchAudits(lookupKey, id);
            this.fetchAdminAudits(this.auditToAdminAudit[lookupKey], id);
        }
    }

    fetchAudits(lookupKey, id) {
        api.get(`${spleisUrl}/api/admin/audit?${lookupKey}=${id}`)
            .then((res) => {
                return this.setState({
                    audits: res,
                });
            })
            .catch((err) => showError(err));
    }

    fetchAdminAudits(lookupKey, lookupValue) {
        api.get(`${spleisUrl}/api/admin/audit/admin?lookupKey=${lookupKey}&lookupValue=${lookupValue}`)
            .then((res) => {
                return this.setState({
                    adminAudits: res,
                });
            })
            .catch((err) => showError(err));
    }

    render() {
        return <Audits audits={this.state.audits} adminAudits={this.state.adminAudits} />;
    }
}

export default AuditContainer;
