import React, { useEffect, useState } from 'react';
import FormButton from '../../FormButton/FormButton';
import api from '../../api';
import { spleisUrl } from '../../config';
import { showError, showInfo } from '../../store';
import styles from './PromotedGiverLimit.module.scss';

const PromotedGiverLimit = ({ project }) => {
    const [limit, setLimit] = useState(project?.profile?.promotedGiverLimit || 5000);

    useEffect(() => {
        setLimit(project?.profile?.promotedGiverLimit || 5000);
    }, [project]);

    const onSubmit = () => {
        api.post(`${spleisUrl}/api/admin/project/${project.id}/update-profile`, {
            profile: {
                promotedGiverLimit: limit,
            },
        })
            .then(() => {
                showInfo('Oppdaterte grensen for promoterte givere');
                window.notify();
            })
            .catch(() => {
                showError('Klarte ikke å oppdatere grensen for promoterte givere');
            });
    };

    return (
        <FormButton title={`Prom. giver grense`} onClick={onSubmit}>
            <label className={styles.label}>
                <p>Givere må gi minst så mye for å kunne bli promotert på spleisen:</p>
                <input type="number" value={limit} onChange={(e) => setLimit(parseInt(e.target.value))} />
            </label>
        </FormButton>
    );
};

export default PromotedGiverLimit;
