import React, { createRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import api from '../api';
import { regnskapUrl } from '../config';
import { showError, showInfo } from '../store';
import Button from '../Button/Button';

export const VippsSettlement = () => {
    const fileupload = createRef();

    const upload = async (e) => {
        e.preventDefault();
        const file = fileupload.current.files[0];
        if (!file) {
            return;
        }
        const reader = new FileReader();

        reader.onload = (cb) => {
            api.upload(`${regnskapUrl}/api/vipps-settlement`, cb.currentTarget.result)
                .then((res) => {
                    showInfo(`Lastet opp ${res.successes} linjer, ${res.failures} feilet`);
                    fileupload.current.value = '';
                })
                .catch((err) => showError('Feil ved filopplasting ' + err));
        };

        reader.readAsText(file);
    };

    return (
        <>
            <Helmet>
                <title>Last opp Vipps Settlement</title>
            </Helmet>
            <form>
                <input type={'file'} ref={fileupload} />
                <Button onClick={upload}>Last opp Vipps Settlement</Button>
            </form>
        </>
    );
};
