import React, { useEffect, useState } from 'react';
import FormButton from '../../FormButton/FormButton';
import api from '../../api';
import { spleisUrl } from '../../config';
import { showError, showInfo } from '../../store';
import styles from './ChangeProjectProfileTech.module.scss';
import ace from 'brace';
import { JsonEditor as Editor } from 'jsoneditor-react';
import { diff } from 'jsondiffpatch';

const ChangeProjectProfileTech = ({ project }) => {
    const [values, setValues] = useState(project.profile || {});

    useEffect(() => {
        setValues(project.profile || {});
    }, [project]);

    const onSubmit = () => {
        const profileDiff = diff(project?.profile || {}, values) || {};
        const changedProfileValues = Object.entries(profileDiff).reduce((acc, [key]) => {
            acc[key] = values[key];
            return acc;
        }, {});
        api.post(`${spleisUrl}/api/admin/project/${project.id}/update-profile`, {
            profile: changedProfileValues,
        })
            .then(() => {
                showInfo('Endret profil');
                window.notify();
            })
            .catch(() => {
                showError('Klarte ikke å endre profil');
            });
    };

    return (
        <FormButton title={`Profil (tech)`} onClick={onSubmit}>
            <Editor
                mode="code"
                allowedModes={['code', 'tree']}
                value={values}
                onChange={setValues}
                htmlElementProps={{
                    className: styles.jsonInput,
                }}
                ace={ace}
            />
        </FormButton>
    );
};

export default ChangeProjectProfileTech;
