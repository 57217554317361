import React, { Component } from 'react';
import moment from 'moment/moment';
import { formatMoney } from '../formatters';
import { DATE_FORMAT } from '../dates';
import VisibleIf from '../VisibleIf/VisibleIf';
import UserLink from '../SmartLinks/UserLink';
import ProjectLink from '../SmartLinks/ProjectLink';
import ShowMore from '../ShowMore/ShowMore';

const ASCENDING = 'ascending';
const DESCENDING = 'descending';
const SORT_NUMBER = 'number';
const SORT_DATE = 'date';

class RefundTable extends Component {
    state = {
        sort: 'amount',
        direction: DESCENDING,
        type: SORT_NUMBER,
    };

    setSort = (field, sortType) => {
        const { sort, direction } = this.state;

        let newDirection = DESCENDING;
        if (field === sort && direction === DESCENDING) {
            newDirection = ASCENDING;
        }

        this.setState({ sort: field, direction: newDirection, type: sortType || SORT_NUMBER });
    };

    compareNumbers = (a, b) => parseInt(b, 0) - parseInt(a, 0);
    compareDates = (a, b) => moment(b).diff(moment(a));

    sort = (a, b) => {
        const { sort, direction, type } = this.state;

        let sortFunc = this.compareNumbers;

        if (type === SORT_DATE) {
            sortFunc = this.compareDates;
        }

        if (direction === DESCENDING) {
            return sortFunc(a[sort], b[sort]);
        } else {
            return sortFunc(b[sort], a[sort]);
        }
    };

    render() {
        const refunds = this.props.data || [];
        return (
            <div className="project-table-data">
                <header className="project-table-data-header">
                    {this.props.title && <h2>{this.props.title}</h2>}
                    <div>{refunds.length} refusjoner</div>
                </header>

                <VisibleIf isVisible={refunds.length > 0}>
                    <ShowMore
                        items={refunds.sort(this.sort)}
                        listCreator={showMoreRefunds => {
                            return (
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>
                                                <button onClick={() => this.setSort('id')}>#</button>
                                            </th>
                                            <th>
                                                <button onClick={() => this.setSort('from_user')}>BrukerId</button>
                                            </th>
                                            <th>
                                                <button onClick={() => this.setSort('to_project')}>ProsjektId</button>
                                            </th>
                                            <th>
                                                <button onClick={() => this.setSort('created_at', SORT_DATE)}>
                                                    Innbetalingstid
                                                </button>
                                            </th>
                                            <th>
                                                <button onClick={() => this.setSort('refunded_at', SORT_DATE)}>
                                                    Refusjonstid
                                                </button>
                                            </th>
                                            <th>Type</th>
                                            <th>
                                                <button onClick={() => this.setSort('amount')}>Beløp</button>
                                            </th>
                                            <th>Giver</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {showMoreRefunds.map(r => {
                                            const type = r.stripe_id
                                                ? 'Stripe'
                                                : r.mcash_tid
                                                    ? 'mCASH'
                                                    : r.invoice_number
                                                        ? 'Faktura'
                                                        : r.vipps_id
                                                            ? 'Vipps'
                                                            : 'Ukjent';
                                            return (
                                                <tr key={r.id}>
                                                    <td>{r.id}</td>
                                                    <td>
                                                        <UserLink id={r.from_user} />
                                                    </td>
                                                    <td>
                                                        <ProjectLink id={r.to_project} />
                                                    </td>
                                                    <td>{moment(r.created_at).format(DATE_FORMAT)}</td>
                                                    <td>{moment(r.refunded_at).format(DATE_FORMAT)}</td>
                                                    <td>{type}</td>
                                                    <td>{formatMoney(r.amount)}</td>
                                                    <td>
                                                        {!r.from_user_meta_data || r.from_user_meta_data.anonymous
                                                            ? 'Anonym'
                                                            : r.from_user_meta_data.name}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            );
                        }}
                    />
                </VisibleIf>
            </div>
        );
    }
}
export default RefundTable;
