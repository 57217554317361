import React, { useState } from 'react';
import withData from '../with-data';
import { spleisUrl } from '../config';
import './ProjectTags.scss';
import Button from '../Button/Button';
import VisibleIf from '../VisibleIf/VisibleIf';
import api from '../api';
import Collapsable from '../Collapsable/Collapsable';

const EXCLUDE_FROM_LIST = ['loved'];
const excludeTags = (tags) => tags.filter((tag) => !EXCLUDE_FROM_LIST.includes(tag));

const AllProjectTags = ({ data, exclude = [], show, onClick, include = '' }) => {
    if (!data) {
        return <span />;
    }

    const tags = (data.tags || []).filter((tag) => !exclude.includes(tag) && !EXCLUDE_FROM_LIST.includes(tag));

    return (
        <ul className={`project-tags-filters${show ? ' show' : ''}`}>
            {tags
                .filter((tag) => tag && tag.includes(include))
                .map((tag) => (
                    <li
                        key={tag}
                        className="tags-filter"
                        tabIndex="0"
                        onClick={() => onClick(tag)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                onClick(tag);
                            }
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'ArrowDown') {
                                e.preventDefault();
                                if (e.target.nextSibling) {
                                    e.target.nextSibling.focus();
                                }
                            }
                            if (e.key === 'ArrowUp') {
                                e.preventDefault();
                                if (e.target.previousSibling) {
                                    e.target.previousSibling.focus();
                                }
                            }
                        }}
                    >
                        {tag}
                    </li>
                ))}
        </ul>
    );
};

export const AllProjectTagsWithData = withData(AllProjectTags, () => `${spleisUrl}/api/admin/project/tag`);

const ProjectTags = ({ data, id }) => {
    const tags = (data || []).tags || [];

    const addTag = async (tag) => {
        await api.put(`${spleisUrl}/api/admin/project/tag/${id}`, { tag });
        window.notify();
    };

    const removeTag = async (tag) => {
        await api.remove(`${spleisUrl}/api/admin/project/tag/${id}`, { tag });
        window.notify();
    };

    return (
        <Collapsable title="Tags" localStorageKey="collapse-project-tags">
            <div className="project-tags-loved">
                {tags && tags.includes('loved') ? (
                    <Button onClick={() => removeTag('loved')}>{'Fjern digger 💔'}</Button>
                ) : (
                    <Button onClick={() => addTag('loved')}>{'Legg til i ❤️'}</Button>
                )}
            </div>
            <ProjectTagsInput id={id} tags={tags} addTag={addTag} removeTag={removeTag}/>
        </Collapsable>
    );
};

export const ProjectTagsInput = ({ id, tags, addTag, removeTag }) => {
    const [filter, setFilter] = useState('');

    const onKeyDown = (e) => {
        if (e.key === 'ArrowDown' && e.target.nextSibling && e.target.nextSibling.childNodes[0]) {
            e.preventDefault();
            e.target.nextSibling.childNodes[0].focus();
        }
    };

    const onKeyPress = (e) => {
        if (e.key === 'Enter') {
            const tag = e.target.value.toLowerCase().trim();
            if (tag.length < 3) {
                return;
            }
            e.target.value = '';
            addTag(tag);
        }
    };

    return (
        <div className="project-tags">
            <VisibleIf isVisible={!!tags && excludeTags(tags).length > 0}>
                <div>
                    <p>Klikk på tag for å fjerne</p>
                    <ul className="project-tags-list">
                        {tags &&
                            excludeTags(tags).map((tag) => {
                                return (
                                    <li
                                        key={tag}
                                        title={tag}
                                        className="project-tag"
                                        onClick={() => removeTag(tag)}
                                    >
                                        {tag}
                                    </li>
                                );
                            })}
                    </ul>
                </div>
            </VisibleIf>
            <div className="project-tags-add">
                <h3>Legg til tag</h3>
                <div className="project-tags-input">
                    <input
                        onKeyPress={onKeyPress}
                        onKeyDown={onKeyDown}
                        onChange={(e) => setFilter(e.target.value)}
                    />
                    <AllProjectTagsWithData id={id} exclude={tags} onClick={addTag} include={filter} />
                </div>
            </div>
        </div>
    );
};

export default withData(ProjectTags, (id) => `${spleisUrl}/api/admin/project/tag/${id}`);
