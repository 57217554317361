export const presets = [
    {
        optionText: 'Vilkårsbrudd: Informer om endring',
        title: 'Vi har fjernet innhold fra spleisen din',
        message: `Hei 👋

Vi har fjernet innhold fra spleisen din (spleis nr. {projectId}) som bryter med våre vilkår. Vi har gjort dette i stedet for å slette den, slik at givere fortsatt har muligheten til å gi penger.

Du står fritt til å gjøre videre endringer på spleisen, men vær oppmerksom på at vi blant annet ikke tillater:
- At Spleis brukes til å sette personer, som kan identifiseres, i en uønsket situasjon og/eller som kan være egnet til å være til skade for dem og/eller pårørende, fordi det er lagt ut sensitive opplysninger om dem på vår plattform.
- Tvistesaker som omhandler omsorgsrett/barnefordeling.
- At barn eksponeres i en negativ sammenheng.
- Vi i Spleis ønsker at det skal utøves en spesiell varsomhet når det kommer til deling av opplysninger og bilder av barn. Vi anbefaler at du tar en titt på veiledningen som Datatilsynet har laget - om bildebruk av barn.
- Når formålet er å dekke utgifter til advokat (inkl. en eventuell rettssak): Av hensyn til begge siders personvern, kan spleisen inneholde informasjon om at man trenger økonomisk hjelp for å kunne ivareta sine interesser og fremme sitt standpunkt i en eventuell sak. Vi ber deg derimot være nøye med å ikke legge inn tekst i spleisen som kan karakteriseres som et partsinnlegg.
- Loddtrekning/Trekning av gevinster blant givere.

Vi viser til våre vilkår: spleis.no/vilkar. Se for øvrig Datatilsynet om personvern.

Dette er en automatisk melding. Dersom du ønsker mer spesifikk informasjon om hvorfor vi har fjernet innhold, kan du sende oss en e-post på hei@spleis.no eller svare på denne meldingen.

Hilsen Spleis-teamet`,
    },
    {
        optionText: 'Pauset: Generell',
        title: 'Din spleis er satt på pause',
        message: `Hei 👋
    
Spleis nr. {projectId} er satt på pause: formålet og beskrivelsen i spleisen din er veldig generell, og vi ber derfor om at du oppdaterer den med mer konkret informasjon om hva du samler inn penger til, hvordan formålet skal gjennomføres og hvem pengene utbetales til. Mottaker bør være en navngitt person eller organisasjon.

Spleisen kan oppdateres her: https://www.spleis.no/project/{projectId}/edit/description

Ved mangel på tilfredsstillende oppdatering innen 3 dager fra denne meldingen er sendt, vil spleisen bli slettet og pengene tilbakebetales til giverne.

Vi ber deg derfor svare på denne meldingen når spleisen er oppdatert.

Hilsen Spleis-teamet`,
    },
    {
        optionText: 'Oppdatering: Generell',
        title: 'Din spleis må oppdateres',
        message: `Hei 👋
    
Formålet og beskrivelsen i spleisen din (spleis nr. {projectId}) anses som generell, og vi ber derfor om at du oppdaterer den med mer konkret informasjon om hva du samler inn penger til, hvordan formålet skal gjennomføres og hvem pengene utbetales til. Mottaker bør være en navngitt person eller organisasjon.

Spleisen kan oppdateres her: https://www.spleis.no/project/{projectId}/edit/description

Dersom spleisen ikke oppdateres med nødvendig informasjon innen 3 dager fra denne meldingen er sendt, vil vi måtte vurdere å sette spleisen på pause.

Vi ber deg derfor svare på denne meldingen når spleisen er oppdatert, slik at vi kan lese over beskrivelsen på nytt 😊

Hilsen Spleis-teamet`,
    },
    {
        optionText: 'Verifisering med BankID og påminnelse om 15-årsgrense',
        title: 'Fullfør brukerprofilen din på Spleis med gyldig kontonummer',
        message: `Hei 👋
    
I forbindelse med opprettelse av spleis nr. {projectId} ber vi deg om å fullføre brukerprofilen din ved å legge til kontonummer og verifisere deg med BankID her: https://www.spleis.no/me/edit-account-number

Vi ønsker også å minne om at aldersgrense for bruk av tjenesten er 15 år.

Ved mangel på oppdatering innen 3 dager, vil spleisen bli slettet.

Hilsen Spleis-teamet`,
    },
    {
        optionText: 'Skal mottaker være org? (husk å endre navn/nr)',
        title: 'Endre mottaker på spleisen?',
        message: `Hei 👋
    
Vi ser du har opprettet en innsamling til ORGANISASJON.

De er registrert i Brønnøysundregistrene med org.nummer ORGANISASJONSNUMMER og vi lurer derfor på om mottaker skal endres direkte til dem?

Gi oss i så fall en bekreftelse på at vi kan utføre denne endringen, ved å svare på denne henvendelsen.

Hilsen Spleis-teamet`,
    },
    {
        optionText: '(blank)',
        title: '',
        message: `Hei 👋

`,
    },
];
