import withData from '../../with-data';
import moment from 'moment';
import { EditReward } from './EditReward';
import { DeleteReward } from './DeleteReward';
import { UndeleteReward } from './UndeleteReward';

import { spleisUrl } from '../../config';
import { formatMoney } from '../../formatters';
import { DATE_FORMAT_SHORT_TIME } from '../../dates';

export const ProjectRewards = ({ data }) => {
    const shortenText = (text, max) => {
        if (text && text.length > max) {
            return text.substring(0, max - 1) + '..';
        } else {
            return text;
        }
    };

    const rewards = data || [];

    return (
        <section className="rewards-container">
            <div className="project-table-data">
                {rewards.length > 0 ? (
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Tittel</th>
                                <th>Beskrivelse</th>
                                <th>Pris</th>
                                <th>Tilgjengelige</th>
                                <th>Kjøpt</th>
                                <th>Adresse?</th>
                                <th>Publisert</th>
                                <th>Valg</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rewards.map((reward) => (
                                <tr key={reward.id}>
                                    <td>{reward.id}</td>
                                    <td className="rewards-cell">{shortenText(reward.title, 200)}</td>
                                    <td className="rewards-cell">{shortenText(reward.description, 200)}</td>
                                    <td>{formatMoney(reward.price)}</td>
                                    <td>{reward.quantity}</td>
                                    <td>{reward.purchased_count}</td>
                                    <td>{reward.requires_address ? '✔' : ''}</td>
                                    <td>{moment(reward.published_at).format(DATE_FORMAT_SHORT_TIME)}</td>
                                    <td>
                                        <EditReward reward={reward} />
                                        {reward.deleted ? (
                                            <UndeleteReward reward={reward} />
                                        ) : (
                                            <DeleteReward reward={reward} />
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>Ingen belønninger</p>
                )}
            </div>
        </section>
    );
};

export default withData(ProjectRewards, (id) => `${spleisUrl}/api/admin/project/${id}/rewards`);
