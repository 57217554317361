import React, { useRef, useState } from 'react';
import api from '../../api';
import { spleisUrl } from '../../config';
import { showError, showInfo } from '../../store';
import Button from '../../Button/Button';
import { getTranslateUrl } from '../../translate';
import { colorizeFlaggedKeywordsInText } from '../project-utils';

const ProjectTitle = ({ project }) => {
    const [isEditing, setIsEdting] = useState(false);
    const textElement = useRef(null);
    const lagre = () => {
        api.post(`${spleisUrl}/api/admin/project/${project.id}/updateProps`, {
            title: textElement.current.value,
        })
            .then(() => {
                showInfo('Tittel oppdatert!');
                setIsEdting(false);
                window.notify();
            })
            .catch((err) => {
                showError(`Feil endring av tittel: ${err}`);
            });
    };

    const title = colorizeFlaggedKeywordsInText(project?.title, project);

    return (
        <h1 className="project-title task-title">
            {isEditing && (
                <span>
                    <input
                        type="text"
                        ref={textElement}
                        className="edit-inline"
                        id="title-box"
                        defaultValue={project.title}
                    />
                    <Button className="edit-button" onClick={lagre}>
                        Lagre
                    </Button>
                    <Button className="edit-button" onClick={() => setIsEdting(false)}>
                        Avbryt
                    </Button>
                </span>
            )}
            {!isEditing && (
                <span>
                    <span dangerouslySetInnerHTML={{ __html: title }} />{' '}
                    <Button className="edit-button" onClick={() => setIsEdting(true)}>
                        Endre
                    </Button>
                    <Button
                        className="edit-button"
                        onClick={() => {
                            window.open(getTranslateUrl({ text: project.title }), 'translate', 'heigth=500,width=500');
                        }}
                    >
                        Oversett
                    </Button>
                </span>
            )}
        </h1>
    );
};
export default ProjectTitle;
