import React, { Component } from 'react';
import { showError } from './store';
import api from './api';

export default function withData(WrappedComponent, idToUrlFn, propsToIdFn = props => props.id, restProps = {}) {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {};
            this.propsToIdFn = propsToIdFn;
            this.fetchData();
        }
        componentDidMount() {
            window.subscribe(this.fetchData);
        }
        componentWillUnmount() {
            window.unsubscribe(this.fetchData);
        }
        componentWillReceiveProps(nextProps) {
            this.setState({ loading: true });
            const nextId = propsToIdFn(nextProps);
            if (nextId !== propsToIdFn(this.props)) {
                this.setState({ data: null });
                if (nextId) {
                    this.fetchData(nextId);
                }
            }
        }
        fetchData = newId => {
            const id = newId ? newId : propsToIdFn(this.props);
            if (id) {
                api.get(idToUrlFn(id))
                    .then(res => this.setState({ loading: false, data: res }))
                    .catch(err => showError(err));
            }
        };
        render = () => (
            <WrappedComponent
                data={this.state.data}
                loading={this.state.loading}
                refreshData={() => this.fetchData(propsToIdFn(this.props))}
                fetchData={this.fetchData}
                {...this.props}
                {...restProps}
            />
        );
    };
}
