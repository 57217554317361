import React, { useState } from 'react';
import TaskContainer from '../TaskContainer/TaskContainer';
import api from '../api';
import { spleisUrl } from '../config';
import styles from './Transactions.module.scss';
import { Helmet } from 'react-helmet';
import Button from '../Button/Button';
import TransactionTable from '../TransactionTable/TransactionTable';
import { showError } from '../store';

const Transactions = ({ match }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searching, setSearching] = useState(null);
    const [searchResult, setSearchResult] = useState([]);

    const fetchTransactions = () => {
        if (!searchTerm) {
            setSearchResult([]);
            return;
        }
        setSearching(true);
        api.get(`${spleisUrl}/api/admin/transaction/search/${encodeURI(searchTerm)}`)
            .then((result) => setSearchResult(result))
            .catch(() => showError('Feil ved søk etter transaksjoner'))
            .finally(() => setSearching(false));
    };

    return (
        <TaskContainer>
            <Helmet>
                <title>Transaksjoner</title>
            </Helmet>
            <header className="task-header">
                <h1 className="task-title">Transaksjoner</h1>
            </header>
            <div className="task-body">
                <div className={styles.section}>
                    <h2>Finn transaksjon</h2>
                    <div>
                        <p>Du kan søke med</p>
                        <ul className={styles.list}>
                            <li>Stripe Intent (starter med pi_)</li>
                            <li>Vipps ID (heltall)</li>
                            <li>Vipps telefonnummer (med +47 eller annen landkode)</li>
                        </ul>
                        <input
                            disabled={searching}
                            className={styles.searchInput}
                            onKeyDown={(e) => e.key === 'Enter' && fetchTransactions()}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value || '')}
                        />
                        <Button
                            disabled={searching || !searchTerm}
                            className={`btn ${styles.searchButton}`}
                            onClick={fetchTransactions}
                        >
                            {searching ? 'Søker...' : 'Søk'}
                        </Button>
                    </div>
                </div>
                {searchResult.length > 0 && (
                    <div className={styles.section}>
                        <TransactionTable data={searchResult} showProjectId showUserId initialShowCount={1000} title="Innbetalinger" />
                    </div>
                )}
            </div>
        </TaskContainer>
    );
};

export default Transactions;
